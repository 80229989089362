import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "adminlogin",
    component: () => import("../views/AdminLoginView.vue"),
  },
  {
    path: "/dashboard",
    name: "admindashboard",
    component: () => import("../views/AdminDashboardView.vue"),
  },
  {
    path: "/makeappointment",
    name: "makeappointment",
    component: () => import("../views/MakeAppointmentView.vue"),
  },
  {
    path: "/doctorinfo",
    name: "doctorinfo",
    component: () => import("../views/AdminDoctorInfo.vue"),
  },
  {
    path: "/lab",
    name: "lab",
    component: () => import("../views/PathologyLabView.vue"),
  },

  {
    path: "/editdoctor/:id",
    name: "editdoctor",
    component: () => import("../views/EditDoctorView.vue"),
  },
  {
    path: "/doctorappointment",
    name: "doctorappointment",
    component: () => import("../views/AdminDocAppointment.vue"),
  },
  {
    path: "/alldocappointmentlist/:id",
    name: "alldocappointmentlist",
    component: () => import("../views/AllDocAppointmentListView.vue"),
  },
  {
    path: "/adddoctor",
    name: "adddoctor",
    component: () => import("../views/AddDoctorView.vue"),
  },
  {
    path: "/userinfo",
    name: "userinfo",
    component: () => import("../views/UserInfoView.vue"),
  },
  {
    path: "/userdata",
    name: "userdata",
    component: () => import("../views/UserDataView.vue"),
  },

  {
    path: "/patient",
    name: "Patient",
    component: () => import("../views/Patient.vue"),
  },

  {
    path: "/marketingcontent",
    name: "marketingcontent",
    component: () => import("../views/MarketingContentView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
